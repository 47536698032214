#root {
  height: 100%;
}

.site-layout {
  transition: margin-left 0.3s cubic-bezier(0.2, 0, 0, 1) 0s;
  .site-layout-background {
    background: #fff;
  }
}

main.ant-layout-content {
  flex: 0 0 auto;
}

.ant-layout-sider-trigger {
  border-top: 1px solid #eee;
  border-right: 1px solid #eee;
  background: rgba(255, 255, 255, 1);
  svg {
    fill: rgba(0, 0, 0, 0.5);
  }
}

.ant-layout-header {
  border-bottom: 1px solid #eee;
}

.acuitas-failed-sync {
  #rc-tabs-0-tab-2 {
    color: red;
  }
}
